import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
import { getSpotlight } from "../views/utilities/ApiCalls";

export const SpotlightCarousel1 = () => {
  const history = useHistory();
  const [spotlightData, setSpotlightData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const sliderRef = useRef(null); // Slider reference

  useEffect(() => {
    const getData = async () => {
      const res = await getSpotlight();
      if (res.data.status === "success") {
        setSpotlightData(res.data.data);
        console.log("spotlight",res.data.data);
      } else {
        setErrorMessage("Error fetching spotlight");
      }
    };
    getData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false, // We'll use custom arrows
  };

  return (
    <Container>
      <Section>
        <Heading>{t("SpotLight")}</Heading>
        {errorMessage && <Error>{errorMessage}</Error>}
        <SliderContainer>
          {/* Custom Previous Arrow */}
          <CustomPrevArrow onClick={() => sliderRef.current.slickPrev()} />
          
          <CustomSlider ref={sliderRef} {...settings}>
            {spotlightData.map((item) =>
              item.quotes.map((quote) => (
                <SliderItem key={quote._id} >
                  <Spotlight onClick={() => (window.location.href = `${quote.storyLink}`)}>
                    <Content>{quote.quote}</Content>
                    <Name>~ {quote.title}</Name>
                  </Spotlight>
                </SliderItem>
              ))
            )}
          </CustomSlider>

          {/* Image on the right */}
          <Image 
          onClick={() => (window.location.href = `${spotlightData[0].profileLink}`)}
            src={
              `${spotlightData[0]?.imageUrl}` ||
              "https://images.unsplash.com/photo-1485579149621-3123dd979885?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
            alt={spotlightData[0]?.author}
          />
          
          {/* Custom Next Arrow */}
          <CustomNextArrow onClick={() => sliderRef.current.slickNext()} />
        </SliderContainer>
      </Section>
    </Container>
  );
};

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowLeft onClick={onClick}>
      <PrevArrow />
    </ArrowLeft>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowRight onClick={onClick}>
      <NextArrow />
    </ArrowRight>
  );
};

const ArrowLeft = styled.div`
  position: absolute;
  top: 49px;
    left: -2rem;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 2;
  color: white;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c2c5c5;
  border-radius: 50px;
`;
const PrevArrow = styled.div`
  color: black;
  &:before {
    content: "<";
    font-size: 20px;
    font-weight: bold;
  }
`;
const NextArrow = styled.div`
  color: black;
  &:before {
    content: ">";
    font-size: 20px;
    font-weight: bold;
  }
`;

const ArrowRight = styled.div`
  position: absolute;
  top: 50px;
  right: -2rem;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 2;
  color: white;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c2c5c5;
  border-radius: 50px;
`;

const CustomSlider = styled(Slider)`
  flex: 4; /* Take 80% of the container's width */
  height: 100%; /* Match the height of the container */
  overflow: hidden;

  .slick-arrow {
    z-index: 2;
  }
  .slick-prev {
    left: -30px;
  }
  .slick-next {
    right: -30px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const SliderItem = styled.div`
  position: relative;
  overflow: visible; /* Allow overflow to be visible */
  border-radius: 12px;
  cursor: pointer;
  width: 100%; /* Ensure it takes full width */
  height: 140px; /* Fixed height for larger screens */
  display: flex;
  transition: transform 0.5s ease, box-shadow 0.3s ease;

  @media (max-width: 768px) {
    height: 140px; /* Adjust height for tablets */
  }

  @media (max-width: 480px) {
    height: 136px; /* Adjust height for mobile devices */
    width: 100%; /* Ensure the width is full on mobile */
    border-radius: 8px; /* Smaller border radius for mobile */
  }

  &.active {
    transform: scale(1.05);
    z-index: 1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  &:hover {
    opacity: 0.9;
  }
`;
const Spotlight = styled.div`
  width: 100%;
  height: 42%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding: 50px;
  overflow: visible;
  margin-left:90px;

  @media (max-width: 840px) {
  margin-left:75px;
  width:90%;
  }
  @media (max-width: 580px) {
  margin-left:46px;
  padding-left: 0px;
  width:100%;
  }

  @media (max-width: 480px) {
    width: 100%;
        padding-left: 0px;
        padding-right: 12px;
        margin-left: 15px;
                align-items: flex-start;


  }
  &.active {
    transform: scale(1.05);
    z-index: 1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  &:hover {
    opacity: 0.9;
  }
`;
const Content = styled.div`
  color: white;
  width: 100%;
text-align: center;
 word-wrap: break-word; /* Ensures long words are wrapped to the next line */
  word-break: break-word; /* Break long words to fit within the container */
  white-space: normal; /* Allows text to wrap onto the next line */
@media (max-width: 480px) {
text-align: left;

    font-size: 13px;
  }
`;
const Name = styled.div`
  color: white;
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;
const Image = styled.img`
  flex: 1; /* Take 20% of the container's width */
  max-width: 157px;
    height: 100px;
    cursor: pointer;

  object-fit: cover;
  border-radius: 10px;
  // @media (max-width: 480px) {
  //   // font-size: 13px;
  //   display:none;
  // }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 191px;
  padding: 30px;
  background: #0f1817;
  box-sizing: border-box;
  overflow: visible; /* Allow overflow to be visible */
`;

const Section = styled.section`
  margin-bottom: 60px;
`;

const Heading = styled.h1`
  font-size: 1.6em;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
`;

const Error = styled.div`
  color: red;
  text-align: center;
  font-size: 1.3em;
  margin: 40px 0;
`;

const SliderContainer = styled.div`
  display: flex; /* Arrange children in a row */
  width: 79%; /* Ensure it spans the full width of its parent */
  height: 106px;
  margin: 0 auto;
  gap: 4px; /* Add some spacing between slider and image */
  align-items: center; /* Center items vertically */
  position:relative;
  border: 1px solid white;
    border-radius: 10px;

  @media (max-width: 768px) {
    // flex-direction: column; /* Stack slider and image vertically on smaller screens */
    gap: 10px; /* Adjust gap for smaller screens */
  }

  @media (max-width: 1189px) {
    width: 89%; /* Adjust width for tablets if needed */
  }

  @media (max-width: 480px) {
    width: 90%; /* Adjust width for mobile devices */
  }
`;
