import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

import axios from "axios";
import { getTokenDetails } from "./utilities/CommonFunction";
import { getEventsQuestions, submitUserResponses } from "./utilities/ApiCalls";
import { toast, Toaster } from "sonner";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Layout from "../Layout/Layout";

const EventForm = (props) => {
  const query = new URLSearchParams(props.location.search);
  const location = useLocation();
const { EventId } = location.state || {};
  const history = useHistory();

  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [eventDetails, setEventDetails] = useState({});

  const [questions, setQuestions] = useState([{}]);
  const [show, setShow] = useState(false);
  const [showerror, setshowerror] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        console.log(EventId);
        const res = await getEventsQuestions(EventId);
        setEventDetails(res.data.data);
        setQuestions(res.data.data.questions);
        console.log("questions", res.data.data.questions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };
    fetchQuestions();
  }, []);

  const handleChange = (questionId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (!validateForm()) {
      setSubmitting(false);
      return;
    }

    try {
      let token = localStorage.getItem("loginDetails");
      const userId = await getTokenDetails(token);

      const formattedAnswers = questions.map((question) => {
        let answer = answers[question._id];
        const otherAnswer = answers[`${question._id}_other`];

        // Handle different input types
        if (question.allowOther) {
          if (question.inputType === "checkbox") {
            answer = (answer || [])
              .map((item) => (item === "Other" ? otherAnswer : item))
              .filter((item) => item !== "Other");
          } else if (answer === "Other") {
            answer = otherAnswer;
          }
        }

        return {
          questionId: question._id,
          questionText: question.questionText,
          answer:
            question.inputType === "checkbox" ? answer || [] : answer || "",
        };
      });

      const res = await submitUserResponses({
        userId: userId._id,
        formId: EventId,
        answers: formattedAnswers,
      });
      setSubmitting(false);

      setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 2000);
    
    setTimeout(()=>{
      history.push({
        pathname: `/`,
      });
    }, 2000)
      setAnswers({})
    } catch (error) {
      setSubmitting(false);

      setshowerror(true);

      setTimeout(()=> {
        setshowerror(false)
      }, 2000)
      setTimeout(()=>{
        history.push({
          pathname: `/`,
        });
      }, 2000)
      
    }
  };

  const validateForm = () => {
    const newErrors = {};
    questions.forEach((question) => {
      if (question.isRequired) {
        const answer = answers[question._id];
        const otherAnswer = answers[`${question._id}_other`];

        // Validation logic for different input types
        const isAnswerEmpty =
          !answer ||
          (Array.isArray(answer) && answer.length === 0) ||
          (typeof answer === "string" && answer.trim() === "");

        const isOtherOptionInvalid =
          question.allowOther &&
          ((answer === "Other" &&
            (!otherAnswer || otherAnswer.trim() === "")) ||
            ((answer || []).includes("Other") &&
              (!otherAnswer || otherAnswer.trim() === "")));

        if (isAnswerEmpty || isOtherOptionInvalid) {
          newErrors[question._id] = "This field is required";
        }
      }
    });
    setErrors(newErrors);
    setTimeout(() => {
      setErrors({});
    }, 2000);
    return Object.keys(newErrors).length === 0;
  };
  const renderQuestion = (question) => {
    switch (question.inputType) {
      case "text":
        return (
          <>
            <Input
              key={question._id}
              type="text"
              value={answers[question._id] || ""}
              onChange={(e) => handleChange(question._id, e.target.value)}
              placeholder="Enter your answer"
            />
            {question.allowOther && (
              <Input
                type="text"
                value={answers[`${question._id}_other`] || ""}
                onChange={(e) =>
                  handleChange(`${question._id}_other`, e.target.value)
                }
                placeholder="Other option (if applicable)"
                style={{ marginTop: "10px" }}
              />
            )}
          </>
        );

      case "radio":
        return (
          <div>
            <RadioGroup>
              {question.options.map((option) => (
                <RadioLabel key={option}>
                  <input
                    type="radio"
                    name={question._id}
                    value={option}
                    checked={answers[question._id] === option}
                    onChange={() => handleChange(question._id, option)}
                  />
                  {option}
                </RadioLabel>
              ))}

              {question.allowOther && (
                <RadioLabel>
                  <input
                    type="radio"
                    name={question._id}
                    value="Other"
                    checked={answers[question._id] === "Other"}
                    onChange={() => handleChange(question._id, "Other")}
                  />
                  Other
                </RadioLabel>
              )}
            </RadioGroup>

            {question.allowOther && answers[question._id] === "Other" && (
              <Input
                type="text"
                placeholder="Please specify"
                value={answers[`${question._id}_other`] || ""}
                onChange={(e) =>
                  handleChange(`${question._id}_other`, e.target.value)
                }
                style={{ marginTop: "10px" }}
              />
            )}
          </div>
        );

      case "select":
        return (
          <>
            <Input
              as="select"
              value={answers[question._id] || ""}
              onChange={(e) => handleChange(question._id, e.target.value)}
            >
              <option value="">Select an option</option>
              {question.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
              {question.allowOther && <option value="Other">Other</option>}
            </Input>

            {question.allowOther && answers[question._id] === "Other" && (
              <Input
                type="text"
                placeholder="Please specify"
                value={answers[`${question._id}_other`] || ""}
                onChange={(e) =>
                  handleChange(`${question._id}_other`, e.target.value)
                }
                style={{ marginTop: "10px" }}
              />
            )}
          </>
        );

      case "checkbox":
        return (
          <>
            <CheckboxGroup>
              {question.options.map((option) => (
                <CheckboxLabel key={option}>
                  <input
                    type="checkbox"
                    checked={(answers[question._id] || []).includes(option)}
                    onChange={() => {
                      const current = answers[question._id] || [];
                      const newValue = current.includes(option)
                        ? current.filter((item) => item !== option)
                        : [...current, option];
                      handleChange(question._id, newValue);
                    }}
                  />
                  {option}
                </CheckboxLabel>
              ))}

              {question.allowOther && (
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    checked={(answers[question._id] || []).includes("Other")}
                    onChange={() => {
                      const current = answers[question._id] || [];
                      const newValue = current.includes("Other")
                        ? current.filter((item) => item !== "Other")
                        : [...current, "Other"];
                      handleChange(question._id, newValue);
                    }}
                  />
                  Other
                </CheckboxLabel>
              )}
            </CheckboxGroup>

            {question.allowOther &&
              (answers[question._id] || []).includes("Other") && (
                <Input
                  type="text"
                  placeholder="Please specify"
                  value={answers[`${question._id}_other`] || ""}
                  onChange={(e) =>
                    handleChange(`${question._id}_other`, e.target.value)
                  }
                  style={{ marginTop: "10px" }}
                />
              )}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Layout>
      <GlobalStyle />
      <FormContainer
        style={{

          height: "100px",
          backgroundImage: `url('https://lh7-us.googleusercontent.com/6qV5Zva4wqKxu9r8LFKt6imDvNfbW7vBBR7YcFPcftpIyeLWpnDBlzhvQK5k3oQmvQsli3CMVTK-Zf_HMFqW0Qnm7_4pJErrYKjtdI7Sk--nLpvkDWLEv6lp9B7asxtcP_6expQ8TeX6G0djkLI9wBapSSHd3g')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          marginBottom: "5px",
        }}
      />
      {show && (
        <div style={styles.overlay}>
          <div style={styles.modal}>
            <p style={{ fontSize: "20px" }}>{eventDetails.successMsg}</p>
          </div>
        </div>
      )}
      {showerror && (
        <div style={styles.overlay}>
          <div style={styles.modal}>
            <p style={{ fontSize: "20px" }}>You have already submitted.</p>
          </div>
        </div>
      )}
      <FormContainer style={{ marginTop: "5px" }}>
        <FormHeader>
          <FormTitle>{eventDetails.name}</FormTitle>
          <FormDescription>{eventDetails.description}</FormDescription>
        </FormHeader>
        <form onSubmit={handleSubmit}>
          {questions.map((question) => (
            <QuestionSection key={question._id}>
              <QuestionLabel required={question.isRequired}>
                {question.questionText}
                {question.isRequired && <span style={{ color: "red" }}>*</span>}
              </QuestionLabel>
              {renderQuestion(question)}
              {errors[question._id] && (
                <ErrorText>{errors[question._id]}</ErrorText>
              )}
            </QuestionSection>
          ))}
          <QuestionSection2>
          <p style={{textAlign:"center", color:"gray", fontFamily:"italic"}}>By registering for the event, I agree and accept to and have no objection in the use of the video / photography of my performance / attendance to be shared with the social media or any other platforms  of  Daxinataha Mastishk Media Pvt Ltd or StoryTent or KORAL or any other partners for publicity, marketing or other purposes.</p>
          </QuestionSection2>
          <QuestionSection3>
            <div
              style={{
                width: "100%",
                display: "flex",
                "justify-content": "space-between",
              }}
            >
              <ClearForm onClick={()=> setAnswers({})}>Clear Form</ClearForm>
              <SubmitButton type="submit">
                {submitting ? "Submitting..." : "Submit"}
              </SubmitButton>
            </div>
          </QuestionSection3>
        </form>
      </FormContainer>
    </Layout>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Ensures it appears above other content
  },
  modal: {
    background: "#fff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",

    width: "auto", // Responsive width
    textAlign: "center",
  },
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: italic;
    // background-color: rgb(15, 24, 23);
    // background-color: #efefef;
    background-color: #0f1817;

  }
`;

const FormContainer = styled.div`
  width: 500px;
  box-sizing: border-box;
  margin: 20px auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    width: 450px;
  }
  @media (max-width: 480px) {

    width: 400px;
  }
  @media (max-width: 404px) {

    width: 350px;
  }
`;

const FormHeader = styled.div`
  background-color: #0b3239;
  color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  text-align:center;
`;

const FormTitle = styled.h1`
  font-size: 32px;
  margin: 0;
  font-weight: 400;
`;

const FormDescription = styled.p`
  margin: 8px 0 0;
  font-size: 16px;
  opacity: 0.7;
  color: white;
`;

const QuestionSection = styled.div`
  padding: 24px;
  border-bottom: 1px solid #e0e0e0;
`;
const QuestionSection3 = styled.div`
  padding: 24px;
  padding-top:12px;

  border-bottom: 1px solid #e0e0e0;
`;
const QuestionSection2 = styled.div`
  padding: 24px;
  padding-top:12px;
  padding-bottom:4px;
  border-bottom: 1px solid #e0e0e0;
`;

const QuestionLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  font-size: 16px;
  color: black;
  font-weight: ${(props) => (props.isRequired ? "500" : "400")};

  span {
    color: white;
    margin-left: 4px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #4285f4;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 8px 0;
  cursor: pointer;

  input {
    margin-right: 12px;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  margin: 8px 0;
  cursor: pointer;

  input {
    margin-right: 12px;
  }
`;

const ClearForm = styled.button`
  color: black;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 20px;

  &:disabled {
    background-color: #dadce0;
    cursor: not-allowed;
  }
`;
const SubmitButton = styled.button`
  background-color: rgb(95, 42, 4);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 20px;

  //   &:hover {
  //     background-color: #3367d6;
  //   }

  &:disabled {
    background-color: #dadce0;
    cursor: not-allowed;
  }
`;

const ErrorText = styled.div`
  color: #d93025;
  font-size: 14px;
  margin-top: 8px;
`;

export default EventForm;
