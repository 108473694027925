import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Context from "../Context";
import { PoetryCard } from "./poetries";
import axios from "axios";
import Image from "../Images/Defaultprofile.png";
import Layout from "../Layout/Layout";
import instagramLogo from "../Images/instagramLogo.png";
import twitterLogo from "../Images/twitterLogo.png";
import facebookLogo from "../Images/facebookLogo.png";
import link from "../Images/link.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import firstimg from "../Images/1.png";
import bannerImage1 from "../Images/banner1.png";
import HeartImg from "../Images/heart.png";
import bannerImage2 from "../Images/banner2.png";
import div2img from "../Images/div2img.png";
import { getTokenDetails } from "./utilities/CommonFunction";
import ContestCarousel from "../Componets/contestCarousel";
import {
  getHomePagePosts,
  getHomePagePostsAdditional,
  getOpenMicContests,
  userLike,
  getAllContests,
  userLikeFunction,
} from "./utilities/ApiCalls";
import { Carousel } from "react-bootstrap";
// library for popup
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../../src/css/style.css";
import "../css/Home.css";
import Poll from "../views/Poll";
import { MdOutlineSignalCellularConnectedNoInternet4Bar } from "react-icons/md";
import {
  Command,
  Cross,
  CrossIcon,
  CrosshairIcon,
  Heart,
  HeartCrack,
  Link2,
  MessageCircle,
  Plus,
  Send,
  Share,
} from "lucide-react";
import Default from "../Images/Defaultprofile.png";
import { useModel } from "../hooks/modelcontex";
import ReactGA from "react-ga4";

import "moment/locale/hi";
import "moment/locale/en-gb";
import {CustomCarousel} from "../Componets/CustomCarousel";

import { SpotlightCarousel1 } from "../Componets/SpotLight1";

const Axios = require("axios");
const cookie = require("js-cookie");

const Constant = require("../../src/views/Constant");

const today = moment(new Date()).format("YYYY-MM-DD");

export default function Home() {
  const [bannerList, setBannerList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [storyList, setStoryList] = useState([]);
  const [audioList, setAudioList] = useState([]);
  const [moviesList, setMoviesList] = useState([]);
  const [token, setToken] = useState(null);
  const [poemList, setPoemList] = useState([]);
  const [contestList, setContestList] = useState([]);
  const [openmicList, setOpenmicList] = useState([]);
  // const [responseData, setResponseData] = useState([]);
  const [isLodding, setIsLodding] = useState(true);
  const history = useHistory();
  const [getClipboard, setClipboard] = useState("");
  const [getEventList, setEventList] = useState([]);
  const { state, dispatch } = useContext(Context);
  const [audioAccess, setAudioAccess] = useState(0);
  const [videoAccess, setVideoAccess] = useState(0);
  const [userId, setUserId] = useState(null);
  const bannerImages = [bannerImage1, bannerImage2];
  const [poem, setpoem] = useState([]);
  const [postpoem, setpostPoem] = useState([]);
  const [poststory, setpostsotry] = useState([]);
  const [post, setPost] = useState([]);
  const [loading, setloading] = useState(true);
  const [link, setlink] = useState("");
  const [Like, setLike] = useState("");
  // popup state
  const [open, setOpen] = useState(false);
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const { t, i18n } = useTranslation();
  const owlRef = useRef(null);
  const { Myprofile, myprofile } = useModel();

  const [page, setpage] = useState(1);
  const [Newpage, setNewpage] = useState(1);
  const [sharelink, setsharelink] = useState(false);
  const [retrievedPostIds, setRetrievedPostIds] = useState(new Set());

  const shareRef = useRef(null);

  const handleClickOutside = (event) => {
    if (shareRef.current && !shareRef.current.contains(event.target)) {
      setsharelink(false);
    }
  };
  const setMomentLocale = (lng) => {
    if (lng === "Hindi") {
      moment.locale("hi");
    } else {
      moment.locale("en-gb"); // Use 'en-gb' for English
    }
  };

  useEffect(() => {
    // Set moment locale based on the current language in i18next
    const token = localStorage.getItem("loginDetails");
    if(!token){
      history.push("/login");
      return;
    }
    setMomentLocale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const [livestreamLink, setLivestreamLink] = useState("");

  const [contestData, setContestData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enteredCode, setEnteredCode] = useState("");
  const [verificationResult, setVerificationResult] = useState("");
  const [isover, setisover] = useState(false);

  const handleJoinClick = () => {
    setIsModalOpen(true);
  };

  const divRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the div
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    // Adding event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Cleaning up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const fetchContests = async () => {
    try {
      console.log("Fetching Contests...");
      const allContestsResult = await getAllContests({});
      console.log("Contests Response:", allContestsResult);

      if (allContestsResult.status === "Success") {
        const sortedContests = allContestsResult.data.sort((a, b) => {
          const dateTimeA = new Date(`${a.startDate}T${a.startTime}`);
          const dateTimeB = new Date(`${b.startDate}T${b.startTime}`);
          return dateTimeB - dateTimeA;
        });
        setContestData(sortedContests);
      }
    } catch (err) {
      // setError("Error fetching contests: " + err.message);
      console.error("Error fetching contests:", err);
    }
  };

  const getPostData = () => {
    if (isover === false) {
      getHomePagePosts(page).then((response) => {
        let newdata = [];
        if (response.data?.length > 0) {
          console.log("response----------------->", response);
          newdata = response?.data || [];
        }
        // const newStories = response?.data || [];
        if (newdata?.length === 0) {
          setisover(true);

          getHomePagePostsAdditional(Newpage).then((response) => {
            console.log("response additional----------------->", response);
            const newPoems = response.data.poems || [];
            const newStories = response.data.stories || [];
            const filteredPoems = newPoems.filter(
              (poem) => !retrievedPostIds.has(poem._id)
            );
            const filteredStories = newStories.filter(
              (story) => !retrievedPostIds.has(story._id)
            );

            setPost((prev) => [...prev, ...filteredPoems, ...filteredStories]);
            setloading(false);
            setRetrievedPostIds((prevIds) => {
              const newIds = new Set(prevIds);
              filteredPoems.forEach((poem) => newIds.add(poem._id));
              filteredStories.forEach((story) => newIds.add(story._id));
              return newIds;
            });
          });
        }
        setPost((prev) => [...prev, ...newdata]);

        setloading(false);
        setRetrievedPostIds((prevIds) => {
          const newIds = new Set(prevIds);
          newdata.forEach((post) => newIds.add(post._id));
          return newIds;
        });
      });
    } else {
      getHomePagePostsAdditional(Newpage).then((response) => {
        // console.log("else part")
        const newPoems = response.data.poems || [];
        const newStories = response.data.stories || [];
        // Filter out already retrieved posts
        const filteredPoems = newPoems.filter(
          (poem) => !retrievedPostIds.has(poem._id)
        );
        const filteredStories = newStories.filter(
          (story) => !retrievedPostIds.has(story._id)
        );

        setPost((prev) => [...prev, ...filteredPoems, ...filteredStories]);
        setpostPoem((prev) => [...prev, ...newPoems]);
        setpostsotry((prev) => [...prev, ...newStories]);
        setloading(false);
        setRetrievedPostIds((prevIds) => {
          const newIds = new Set(prevIds);
          filteredPoems.forEach((poem) => newIds.add(poem._id));
          filteredStories.forEach((story) => newIds.add(story._id));
          return newIds;
        });
      });
    }
  };

  useEffect(() => {
    getPostData();
  }, [page, Newpage]);

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setloading(true);

        if (isover === false) {
          console.log("isover for page", isover);
          setpage((prev) => {
            console.log("Setting page from", prev, "to", prev + 1);
            return prev + 1;
          });
        } else {
          console.log("isover for newPage", isover);
          setNewpage((prev) => {
            console.log("Setting newpage from", prev, "to", prev + 1);
            return prev + 1;
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {}, [isover]);

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, [isover]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  console.log(post);
  const copyToClipboard = async (text) => {
    navigator.clipboard.writeText(text);
    console.log(text);
  };

  function like(data) {
    let likedata = {};
    if (data.type === "story") {
      likedata.type = 1;
    } else if (data.type === "poem") {
      likedata.type = 2;
    }

    // console.log(likedata.type);
    // console.log(data.userLike);
    let likeRequest = {
      moduleId: data._id,
      type: likedata.type,
      user_id: "",
      title: data.name,
      description: data.description,
      thumbnail: data.thumbnail,
    };

    ReactGA.event({
      category: "User Interaction",
      action: data.userLike === 1 ? "Unlike" : "Like",
      label: likedata.type === 1 ? "Story Like" : "Poem Like",
      value: data._id,
    });

    userLike(likeRequest).then((response) => {
      console.log(response);
      if (response.message === "Like") {
        setLike(true);
        let currentPost = post;
        console.log(currentPost);
        let updatedPost = currentPost.map((item) => {
          if (item._id === data._id) {
            return {
              ...item,
              no_of_likes: (item.no_of_likes ?? 0) + 1,
              userLike: 1,
            };
          }
          return item;
        });

        console.log(updatedPost);
        setPost(updatedPost);
      } else if (response.message === "Dislike") {
        setLike(true);
        let currentPost = post;
        console.log(currentPost);
        let updatedPost = currentPost.map((item) => {
          if (item._id === data._id) {
            return { ...item, no_of_likes: item.no_of_likes - 1, userLike: 0 };
          }
          return {
            ...item,
            no_of_likes: item.no_of_likes,
          };
        });

        setPost(updatedPost);
      }
    });
  }

  return (
    <Layout>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#0f1817",
          minHeight: "100vh",
        }}
      >
        {/* <SpotlightCarousel/> */}
        <SpotlightCarousel1 />
        <CustomCarousel />
        {/* <ContestCarousel /> */}
        {/* <OpenMicCarousel /> */}
        {/* <EventCarousel/> */}
        <Root>
          <div className={`posts  ${sharelink && "opacity"} `}>
            {post &&
              post?.length > 0 &&
              post?.map((data, index) => {
                return (
                  <div
                    key={index}
                    style={{ color: "white" }}
                    className="post-container"
                  >
                    <Link
                      to={
                        myprofile._id === data.userId
                          ? `/profile`
                          : `/userprofile/${data.userId}`
                      }
                      className=" user-profile"
                    >
                      {data.profilePic === "" ? (
                        <img
                          className="profile"
                          src={Default}
                          alt="profile"
                        ></img>
                      ) : (
                        <img
                          className="profile"
                          src={data?.profilePic}
                          alt="profile"
                        ></img>
                      )}
                      <div className="name">
                        <span>{data.authorName}</span>
                        <span className="duration">
                          {moment(data?.createdAt)?.fromNow()}
                          {console.log(moment(data?.createdAt)?.fromNow())}
                        </span>
                      </div>
                    </Link>
                    {state.token ? (
                      <div
                        onClick={() =>
                          history.push(`/${data.type}-details/${data._id}`)
                        }
                        style={{ color: "white" }}
                        className="heading"
                      >
                        {data.name}
                      </div>
                    ) : (
                      <div
                        onClick={() => history.push(`/login`)}
                        style={{ color: "white" }}
                        className="heading"
                      >
                        {data.name}
                      </div>
                    )}

                    {state.token ? (
                      <div
                        onClick={() =>
                          history.push(`/${data.type}-details/${data._id}`)
                        }
                        className="main-content"
                      >
                        <div className="post-info">
                          <p className=" text">{data.description}</p>
                          {/* {data} */}
                        </div>
                        <div className="thumbnail-img">
                          <img
                            alt=""
                            className="img"
                            src={data.thumbnail}
                          ></img>
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() => history.push(`/login`)}
                        className="main-content"
                      >
                        <div className="post-info">
                          <p className=" text">{data.description}</p>
                          {/* {data} */}
                        </div>
                        <div className="thumbnail-img">
                          <img
                            alt=""
                            className="img"
                            src={data.thumbnail}
                          ></img>
                        </div>
                      </div>
                    )}

                    {state.token ? (
                      <div className="icons">
                        {data.userLike ? (
                          <span>
                            <img
                              alt="heart"
                              onClick={() => like(data)}
                              style={{
                                height: "25px",
                                width: "25px",
                                marginTop: "0px",
                                cursor: "pointer",
                              }}
                              src={HeartImg}
                            />
                            {data.no_of_likes ?? 0}
                          </span>
                        ) : (
                          <span>
                            <Heart
                              style={{ cursor: "pointer" }}
                              onClick={() => like(data)}
                            />
                            {data.no_of_likes ?? 0}
                          </span>
                        )}

                        <span>
                          <MessageCircle
                            onClick={() =>
                              history.push(`/${data.type}-details/${data._id}`)
                            }
                          ></MessageCircle>{" "}
                          {data.number_of_comments}{" "}
                        </span>
                        <Send
                          style={{
                            width: "22px",
                            height: "22px",
                            marginTop: "2px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setsharelink(true);
                            setlink(
                              `https://www.storytent.in/${data.type}-details/${data._id}`
                            );
                          }}
                        ></Send>
                      </div>
                    ) : (
                      <Link
                        style={{ color: "white" }}
                        to={`/login`}
                        className="icons"
                      >
                        <Heart></Heart>
                        <MessageCircle></MessageCircle>
                        <Send></Send>
                      </Link>
                    )}

                    <hr className="hr"></hr>
                  </div>
                );
              })}
            {loading && (
              <div style={{ fontSize: "20px", textAlign: "center" }}>
                {t("Home.Loading")}
              </div>
            )}
          </div>

          {sharelink && (
            <div ref={shareRef} className="share-box">
              <div
                onClick={() => setsharelink(false)}
                style={{ position: "absolute", right: "0px" }}
              >
                <Plus
                  style={{
                    transform: "rotate(45deg)",
                    color: "white",
                    cursor: "pointer",
                  }}
                ></Plus>
              </div>
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "20px",
                }}
              >
                {t("ShareLink.Share")}
              </div>

              <div>
                <div className="share-link-icon">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      copyToClipboard(link);
                      setsharelink(false);
                    }}
                    className="icon-container"
                  >
                    <Link2></Link2>
                  </span>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  {t("ShareLink.CopyLink")}
                </div>
              </div>
            </div>
          )}
        </Root>
      </div>
    </Layout>
  );
}

const Root = styled.div`
  min-height: 582px;
  height: auto;
  display: flex;
  background-color: #0f1817;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .share-box {
    width: 300px;
    height: 120px;
    border-radius: 10px;
    background-color: #023020;
    position: fixed;
    top: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon-container {
    background-color: #e6edea;
    padding: 10px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 100px;
  }

  .share-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .opacity {
    opacity: 0.2;
  }

  .post-container {
    // cursor: pointer;
  }
  .post-info {
    flex-basis: 2/3;
    cursor: pointer;
    width: 100%;
    height: 150px;
    overflow: hidden;
    @media (min-width: 350px) and (max-width: 850px) {
      height: 80px;
      flex-basis: 3/4;
      width: 100%;
      min-width: 180px;
    }
  }
  p {
    color: white;
  }

  .main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    cursor: pointer;
    width: 100%;
    gap: 10px;
    padding-left: 5px;
    padding-right: 5px;

    @media (min-width: 350px) and (max-width: 850px) {
      height: 100px;
    }
  }

  .thumbnail-img {
    display: flex;
    flex-basis: 1/3;

    @media (min-width: 350px) and (max-width: 850px) {
      height: 90px;
      flex-basis: 1/4;
      width: 100%;
      border-radius: 12px;
      width: 180px;
    }
  }

  .hr {
    width: 100%;
    border: none;
    border: 1px solid white;
    margin-bottom: 20px;
    opacity: 0.5;
  }

  .icons {
    gap: 50px;
    margin-left: 10px;
    display: flex;
    margin-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .text {
    width: 95%; /* Set container width */
    margin-left: 5px; /* Add left margin */
    line-height: 1.2; /* Set line height */
    letter-spacing: -0.5px; /* Set letter spacing */

    overflow: hidden; /* Hide any content that overflows the container */
    display: -webkit-box; /* Display as WebKit box */
    -webkit-box-orient: vertical; /* Orient the box vertically */
    -webkit-line-clamp: 8; /* Limit the block to 4 lines */
    white-space: normal; /* Allow text to wrap */
    text-overflow: ellipsis; /* Truncate text with ellipsis (...) */
    text-align: justify;
    @media (min-width: 350px) and (max-width: 850px) {
      -webkit-line-clamp: 4; /* Adjust the line clamp for smaller screens */
    }
  }

  .img {
    width: 100%;
    height: 100%;
    height: 150px;
    width: 350px;
    @media (min-width: 350px) and (max-width: 850px) {
      height: 90px;
      width: 180px;
    }
  }

  .heading {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 30px;
    line-height: 1.1;
    ${"" /* font-weight:bold; */}
    ${"" /* font-family: sans-serif; */}
@media (min-width: 350px) and (max-width: 850px) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  .name {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: #fff;
    // font-size:15px;
    .duration {
      font-size: 10px;
    }
  }
  .user-profile {
    display: flex;
    gap: 10px;
    color: #73807f;
    padding-left: 5px;
    padding-right: 5px;
  }

  .profile {
    height: 45px;
    width: 45px;
    border-radius: 50px;
  }

  .posts {
    margin-top: 30px;
    width: 90%;
    height: auto;
  }

  .input {
    width: 100%;
    padding: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    outline: none;
    border: none;
    display: block;
    padding-left: 10px;
    background-color: #263a38;
  }

  .share-button {
    color: white;
    padding: 5px;
    padding-right: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    outline: none;
    border: none;
    background-color: #5a6f6d;
  }
  .input-container {
    display: flex;
    width: 100%;

    ${"" /* justify-content:space-between; */}
  }

  .label {
    color: white;
  }

  .form {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .slider {
    background-color: #263a38;
    color: white;
    ${"" /* margin-top:75px; */}

    width:100%;
    height: 150px;
    display: flex;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .inside {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider-container {
    margin-top: 75px;
    width: 80%;
    height: auto;
    min-height: 150px;
    ${"" /* max-width:600px; */}
    ${"" /* background-color:red; */}
  }
`;
